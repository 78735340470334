import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { PrimeIcons, MenuItem } from 'primeng/api';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	html?:string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: 'dashboard/default', title: 'Tableau de bord', icon: 'home', type: 'link', bookmark: true
		},
		// {
		// 	title: 'Tableau de bord', icon: 'home', type: 'sub', active: false, children: [
		// 		// { path: '/dashboard/default', title: 'Default', type: 'link' },
		// 		// { path: '/dashboard/e-commerce', title: 'E-Commerce', type: 'link', },
		// 		// { path: '/dashboard/university', title: 'University', type: 'link' },
		// 		// { path: '/dashboard/bitcoin', title: 'Crypto', type: 'link' },
		// 		// { path: '/dashboard/server', title: 'Server', type: 'link' },
		// 		// { path: '/dashboard/project', title: 'Project', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Widgets', icon: 'airplay', type: 'sub', active: false, children: [
		// 		{ path: '/widgets/general', title: 'General', type: 'link' },
		// 		{ path: '/widgets/chart', title: 'Chart', type: 'link' }
		// 	]
		// },
		// {
		// 	title: 'Publications', icon: 'truck', type: 'sub', active: false, children: [
		// 		{ path: '/offres/listing_offres', title: 'Tous', type: 'link' },
		// 		{ path: '/offres/listing_offres_attente', title: 'En attente', type: 'link' },
		// 		{ path: '/vehicules/listing_vehicule_expire', title: 'Véhicules Expirés', type: 'link' },
		// 		{ path: '/vehicules/listing_vehicule_vip', title: 'VIP', type: 'link' },

		// 	//{ path: '/vehicules/listing_vehicules_vendus', title: 'Vendus', type: 'link' },
			
		// 	]
		// },

		// {
		// 	title: 'Annonceurs', icon: 'user', type: 'sub', active: false, children: [
		// 		{ path: '/offres/listing_annonceurs', title: 'Annonceurs', type: 'link' },
			
		// ]
		// },
		
		// {
		// 	title: 'Sécurité', icon: 'bar-chart', type: 'sub', active: false, children: [
		// 		// { path: '/statistiques/listing_victime', title: 'Victimes', type: 'link' },
		// 		//{ path: '/statistiques/listing_nombre_vehicules_vendus', title: "Ofres", type: 'link' },
		// 		//{ path: '/statistiques/offres-poste', title: 'Offres Postées', type: 'link' },
		// 		// { path: '/statistiques/listing_victime_evacue', title: 'Offres par périodes', type: 'link' },
		// 		//{ path: '/statistiques/listing_annonces_periodes', title: 'Annonceurs par période', type: 'link' },
		// 	]
		// },
		
		{
			title: 'Paramétrage', icon: 'settings', type: 'sub', active: false, children: [
				//listing_parametre
				//{ path: '/parametres/listing_profil', title: 'Administrateurs', type: 'link' },
				//{ path: '/parametres/listing_prix', title: 'Prix', type: 'link' },
				//{ path: '/parametres/listing_kilometrage', title: 'kilométrage', type: 'link' },
				//{ path: '/parametres/listing_distance', title: 'Distance', type: 'link' },
				//{ path: '/parametres/listing_marque', title: 'Marque', type: 'link' },
				//{ path: '/parametres/listing_ville', title: 'Ville', type: 'link' },
				{ path: '/parametres/listing_article', title: 'Article', type: 'link' },
				{ path: '/parametres/listing_contrat', title: 'Contrat', type: 'link' },
				{ path: '/parametres/groupe', title: 'Groupe', type: 'link' },
				{ path: '/parametres/historique-connexion', title: 'historique', type: 'link' },
				//{ path: '/parametres/password', title: 'Mot de passe', type: 'link' },
				{ path: '/parametres/groupe', title: 'groupe', type: 'link' },

			]
		},

		{
			title: 'Activite', icon: 'activity', type: 'sub', active: false, children: [
				{ path: '/parametres/listing_abonne', title: 'Abonne', type: 'link' },
				{ path: '/parametres/listing_avis', title: 'Avis', type: 'link' },
				{ path: '/parametres/listing_redacteurs', title: 'Redacteurs', type: 'link' },
				{ path: '/parametres/listing_redacteurs_articles', title: 'Articles redacteurs', type: 'link' },
			]
		},
		// {
		// 	title: 'Astuces',icon:'info', type: 'sub',active: false,children: [
		// 	  { path: '/parametres/astuces', title: 'Astuces', type: 'link' },
		// 	]
		//   }
		  
		  
	
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
