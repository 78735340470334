<div class="row">
    <div class="col-sm-12">
        <!-- <app-card cardTitle="Liste des contrats en cours" cardClass="card-datatable" [options]="false"> -->
            <div class="row align-items-center m-l-0">
                <div class="col-sm-6"></div>
                <div class="col-sm-6 text-right mb-2">
                    <button class="btn btn-success btn-md btn-round has-ripple" (click)="goToCreateContrat()"><i class="feather icon-plus"></i> Ajouter un contrat</button>
                </div>
            </div>
            <div class="table-responsive">
                <table datatable class="table table-striped table-bordered nowrap table-hover">
                    <thead>
                        <tr class="">
                            <th class="text-center">#</th>
                            <th class="text-center">Date Réalisation</th>  
                            <th class="text-center">Montant</th>
                            <th class="text-center">Date Début</th>
                            <th class="text-center">Date Fin</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="isLoading">
                            <td class="text-center" colspan="6">
                                Veuillez patienter pendant le chargement des données...
                            </td>
                        </tr>
                        <!-- <tr *ngFor="let item of items | itPaginator: (page-1)*pageSize: page*pageSize; let i = index">
                            <td class="text-center">{{i+1}}</td>
                            <td class="text-center">{{item.date_realisation | date : 'dd/mm/yyyy'}}</td>
                            <td class="text-center">{{item.montant}}</td>
                            <td class="text-center">{{item.date_debut | date : 'dd/mm/yyyy'}}</td>
                            <td class="text-center">{{item.date_fin | date : 'dd/mm/yyyy'}}</td>

                            <td class="text-center">

                                <div class="btn-group mx-2" (click)="editContrat(item)">
                                    <a class="btn btn-icon btn-outline-success" title="Editer"><i class="feather icon-edit"></i></a>
                                </div>
                                <div class="btn-group mx-2">
                                    <a class="btn btn-icon btn-outline-danger" title="Supprimer"><i class="feather icon-trash-2"></i></a>
        
                                </div>
                                </td>
                        </tr> -->
                    </tbody>

                </table>
            </div>
        <!-- </app-card> -->
    </div>
</div>
