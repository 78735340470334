import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { utilisateur } from 'src/app/shared/model/utilisateur';
import { video } from 'src/app/shared/model/video';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { contrat } from 'src/app/shared/model/contrat';

@Component({
  selector: 'app-contrat-create',
  templateUrl: './contrat-create.component.html',
  styleUrls: ['./contrat-create.component.scss']
})
export class ContratCreateComponent implements OnInit {

  collaborateur: utilisateur = new utilisateur();
  video: video = new video();
  contrat: contrat = new contrat();
  entrepriseId: number;
  dataUser: any = {};
  item: string;
  listVideos: any[] = [];
  listAbonnes: any[] = [];
  isLoading: boolean;

  constructor(
    private userService: UserService,
    private activeModal: NgbActiveModal, 
    private router: Router,
    private localStorageService: LocalStorageService,
    private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private utils: UtilsService,
    private paramService: ParameterService,
  ) {}


  ngOnInit(): void {
    this.getAbonneList();
    this.getVideoList();
    
    
  }

  close() {
    // this.updated.emit("updated");
    //this.activeModal.close();
    this.activeModal.dismiss(false);
  }//end close

  //enregistrer un client
  saveItem() {

    //log
    console.log(this.contrat);

    this.contrat["client_id"] = this.contrat.client.id;
    this.contrat["video_id"] = this.contrat.video.id;

    //enregistrement
    this.paramService.createContrat(this.contrat).subscribe((result: any) => {

      this.loaderService.stop();
      if (result === null || result === undefined) {
        let message = "Une erreur est survenue lors de la création du contrat";
        this.utils.displayErrorMessage (message, this.router);
        return;
      }

      if (result.status === "error") {
        let message = result.message;
        this.utils.displayErrorMessage (message, this.router);
       
        return;
      }
      let message = "Contrat enregistré avec succès";
      this.utils.displaySuccessMessage (message, this.router);
      this.activeModal.dismiss(true);
      //this.activeModal.close();

      // this.router.navigate(["/admin/params/client-list"]);
    }, err => {
      this.loaderService.stop();
      let message = 'Une erreur est survenue lors de la création du contrat.'
      this.utils.displayErrorMessage (message, this.router);
    }
    );

  } //fin saveItem

  //récupérer la liste des vidéos
  getVideoList(): void {
    this.isLoading = true;
    
    //this.loaderService.start();
      this.listVideos = [];
      
    this.paramService.getVideoList().subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;
        //this.loaderService.stop();

        if (resp === null || resp === undefined) {
          this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des vidéos", "Video");
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage(result.message, "Video");
          return;
        }
        this.listVideos = resp.data;
        
        console.log(this.listVideos);
      },
      err => {
        this.loaderService.stop();
        this.isLoading = false;
        this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des vidéos", "Video");
        return;
      }
    );
  } //end getVideoList

  //récupérer la liste des abonnés
  getAbonneList(): void {
 
    this.isLoading = true;
    //this.loaderService.start();
      this.listAbonnes = [];

    this.paramService.getAbonneList().subscribe(
      (result: any) => {
        this.isLoading = false;
        let respAbonne = result;
        //this.loaderService.stop();

        if (respAbonne === null || respAbonne === undefined) {
          this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des abonnés", "Abonné");
          return;
        }
        if (respAbonne.status === "error") {
          this.utils.notifyErrorMessage(result.message, "Abonné");
          return;
        }
        this.listAbonnes = respAbonne.data;
        console.log(this.listAbonnes);
      },
      err => {
        this.loaderService.stop();
        this.isLoading = false;
        this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des abonnés", "Abonné");
        return;
      }
    );
  } //end getAbonneList



}
