import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
//import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { UtilsService } from './shared/services/utils.service';
import { FooterComponent } from '../app/shared/components/footer/footer.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
// import { NgxConfirmBoxModule,NgxConfirmBoxService } from 'ngx-confirm-box';
import {NgConfirmModule} from 'ng-confirm-box';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalStorageService} from './shared/services/storage/localstorage.service'
import { NgbNavConfig, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import {AccordionModule} from 'primeng/accordion';     //accordion and accordion tab
import {MenuItem} from 'primeng/api';  
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { NgxUiLoaderModule} from 'ngx-ui-loader';

import { StepsModule } from 'primeng/steps';

import { FormatMoneyPipe } from './shared/pipes/format_money/format_money.pipe';
import { AuthentificationService } from './shared/services/http/authentication/authentifications.service';
import { AuthService } from './shared/services/http/authentication/auth.service';
import { ItPaginatorPipe } from './shared/pipes/it-paginator/it-paginator.pipe';
import { ContesCreateComponent } from './pages/parametre/destinations/contes-create/contes-create.component';
import { DestinationsCreateComponent } from './pages/parametre/destinations/destinations-create/destinations-create.component';
import { DestinationsDeleteComponent } from './pages/parametre/destinations/destinations-delete/destinations-delete.component';
import { DestinationsEditComponent } from './pages/parametre/destinations/destinations-edit/destinations-edit.component';
import { DestinationsListComponent } from './pages/parametre/destinations/destinations-list/destinations-list.component';
import { ExpressionsCreateComponent } from './pages/parametre/destinations/expressions-create/expressions-create.component';
import { MediasCreateComponent } from './pages/parametre/destinations/medias-create/medias-create.component';
import { PresentationSiteCreateComponent } from './pages/parametre/destinations/presentation-site-create/presentation-site-create.component';
import { PaysCreateComponent } from './pages/parametre/pays/pays-create/pays-create.component';
import { PaysDeleteComponent } from './pages/parametre/pays/pays-delete/pays-delete.component';
import { PaysEditComponent } from './pages/parametre/pays/pays-edit/pays-edit.component';
import { PaysListComponent } from './pages/parametre/pays/pays-list/pays-list.component';
import { VaudounCreateComponent } from './pages/parametre/vaudoun/vaudoun-create/vaudoun-create.component';
import { VaudounDeleteComponent } from './pages/parametre/vaudoun/vaudoun-delete/vaudoun-delete.component';
import { VaudounEditComponent } from './pages/parametre/vaudoun/vaudoun-edit/vaudoun-edit.component';
import { VaudounListComponent } from './pages/parametre/vaudoun/vaudoun-list/vaudoun-list.component';
import { AccueilComponent } from './pages/accueil/accueil.component';
import { ArticleCreateComponent } from './pages/publications/article/article-create/article-create.component';
import { ArticleEditComponent } from './pages/publications/article/article-edit/article-edit.component';
import { ArticleListComponent } from './pages/publications/article/article-list/article-list.component';
import { ArticleMediaComponent } from './pages/publications/article/article-media/article-media.component';
import { ArticleMediaDetailComponent } from './pages/publications/article/article-media-detail/article-media-detail.component';
import { ContratCreateComponent } from './pages/contrat/contrat-create/contrat-create.component';
import { ContratEditComponent } from './pages/contrat/contrat-edit/contrat-edit.component';
import { ContratListComponent } from './pages/contrat/contrat-list/contrat-list.component';
import { VideosCreateComponent } from './pages/publicite/videos/videos-create/videos-create.component';
import { VideosDeleteComponent } from './pages/publicite/videos/videos-delete/videos-delete.component';
import { VideosEditComponent } from './pages/publicite/videos/videos-edit/videos-edit.component';
import { VideosListComponent } from './pages/publicite/videos/videos-list/videos-list.component';
import { VideosStatsComponent } from './pages/publicite/videos/videos-stats/videos-stats.component';
import { SamplePageComponent } from './pages/sample-page/sample-page.component';
import { GroupeListComponent } from './pages/security/groupe/groupe-list/groupe-list.component';
import { HistoriqueConnexionListComponent } from './pages/security/historique_connexion/historique-connexion-list/historique-connexion-list.component';
import { ChangepasswordComponent } from './pages/security/monprofil/changepassword/changepassword.component';
import { MonprofilViewComponent } from './pages/security/monprofil/monprofil-view/monprofil-view.component';
import { PositionnementCreateComponent } from './pages/security/positionnement/positionnement-create/positionnement-create.component';
import { PositionnementListComponent } from './pages/security/positionnement/positionnement-list/positionnement-list.component';
import { ProfilsCreateComponent } from './pages/security/profils/profils-create/profils-create.component';
import { ProfilsDeleteComponent } from './pages/security/profils/profils-delete/profils-delete.component';
import { ProfilsEditComponent } from './pages/security/profils/profils-edit/profils-edit.component';
import { ProfilsListComponent } from './pages/security/profils/profils-list/profils-list.component';
import { RoleListComponent } from './pages/security/roles/role-list/role-list.component';
import { AbonneDonsComponent } from './pages/activite/abonne/abonne-dons/abonne-dons.component';
import { AbonneListComponent } from './pages/activite/abonne/abonne-list/abonne-list.component';
import { AbonneVisitesComponent } from './pages/activite/abonne/abonne-visites/abonne-visites.component';
import { AvisListComponent } from './pages/activite/avis/avis-list/avis-list.component';
import { NewsletterListComponent } from './pages/activite/newsletter/newsletter-list/newsletter-list.component';
import { LibraryService } from './shared/services/app/library.service';
import { OperationService } from './shared/services/http/operation/operations.service';
import { PublicationService } from './shared/services/publications/publication.service';
import { AppService } from './shared/services/app/app.service';
import { ParameterService } from './shared/services/http/parameters/parameters.service';
import { UserService } from './shared/services/user/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RedacteursListComponent } from './pages/redacteurs/redacteurs-list/redacteurs-list.component';
import { ArticlesRedacteursComponent } from './pages/redacteurs/articles-redacteurs/articles-redacteurs.component';
import { EditArticlesRedacteursComponent } from './pages/redacteurs/edit-articles-redacteurs/edit-articles-redacteurs.component';
import { TableModule } from 'primeng/table';

//import { ToastModule } from 'primeng/toast';   

// AoT requires an exported function for factories
// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    ContesCreateComponent,
    DestinationsCreateComponent,
    DestinationsDeleteComponent,
    DestinationsEditComponent,
    DestinationsListComponent,
    ExpressionsCreateComponent,
    MediasCreateComponent,
    PresentationSiteCreateComponent,
    PaysCreateComponent,
    PaysDeleteComponent,
    PaysEditComponent,
    PaysListComponent,
    VaudounCreateComponent,
    VaudounDeleteComponent,
    VaudounEditComponent,
    VaudounListComponent,
    AccueilComponent,
    ArticleCreateComponent,
    ArticleEditComponent,
    ArticleListComponent,
    ArticleMediaComponent,
    ArticleMediaDetailComponent,
    ContratCreateComponent,
    ContratEditComponent,
    ContratListComponent,
    VideosCreateComponent,
    VideosDeleteComponent,
    VideosEditComponent,
    VideosListComponent,
    VideosStatsComponent,
    SamplePageComponent,
    GroupeListComponent,
    HistoriqueConnexionListComponent,
    ChangepasswordComponent,
    MonprofilViewComponent,
    PositionnementCreateComponent,
    PositionnementListComponent,
    ProfilsCreateComponent,
    ProfilsDeleteComponent,
    ProfilsEditComponent,
    ProfilsListComponent,
    RoleListComponent,
    AbonneDonsComponent,
    AbonneListComponent,
    AbonneVisitesComponent,
    AvisListComponent,
    NewsletterListComponent,
    RedacteursListComponent,
    ArticlesRedacteursComponent,
    EditArticlesRedacteursComponent,
    
  ],
  imports: [
    BrowserModule,
    //FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbNavModule,
    NgConfirmModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    StepsModule,
    NgbCarouselModule,
    NgxUiLoaderModule,
    TranslateModule.forRoot(),
    //ToastModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory, 
    //     deps: [HttpClient]
    //   },
    // }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    NgbModule,
    TableModule
  ],
  providers: [
    AuthentificationService, 
    AuthService,
    AdminGuard, 
    SecureInnerPagesGuard, 
    CookieService, 
    DecimalPipe,
    ItPaginatorPipe,
    UtilsService,
    LocalStorageService,
    LibraryService,
    OperationService,
    PublicationService,
    AppService,
    ParameterService, 
    UserService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
