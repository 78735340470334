<div class="row">
    <div class="col-sm-12">
        <!-- <app-card cardTitle="Liste des visites de l'abonné" cardClass="card-datatable" [options]="false"> -->
            <div class="row align-items-center m-l-0">
                <div class="col-sm-6"></div>
                <div class="col-sm-6 text-right mb-2">
                   
                </div>
            </div>
            <div class="table-responsive">
                <table datatable class="table table-striped table-bordered nowrap table-hover">
                    <thead>
                        
                        <tr class="">
                            <th class="text-center">#</th>
                            <th class="text-center">Date de la visite</th>
                            <th class="text-center">Navigation</th>
                            <th class="text-center">Destination</th>
                           


                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="isLoading">
                            <td class="text-center" colspan="4">
                                Veuillez patienter pendant le chargement des données...
                            </td>
                        </tr>

                        <tr *ngFor="let item of items">
                            <!-- <tr *ngFor="let item of items | itPaginator: (page-1)*pageSize: page*pageSize; let i = index"> -->
                            <td class="text-center">{{item.id}}</td>
                            <td class="text-center"> {{item.date | date : 'dd/mm/yyyy'}} </td>
                            <td class="text-center"> {{item.navigation}} </td>
                            <td class="text-center"> {{item.destination_id}} </td>
                            
                           
                        </tr>

                    </tbody>

                </table>

                <ngb-pagination class='float-right mb-2' [(page)]="page" [pageSize]="pageSize"
                [collectionSize]="items.length"></ngb-pagination>

            </div>
        <!-- </app-card> -->
    </div>
</div>
