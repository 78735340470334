export class contrat {
    public id: number;
    public video_id: number;
    public client_id: number;
    public date_debut: string;
    public date_fin: string;
    public date_realisation: string;
    public statut: number;
    public montant: string;

    public client: any;
    public video: any;

}