import * as EmailValidator from 'email-validator';
import { LibraryService } from '../services/app/library.service';

export class user {
  public id: number;
  public email: string;
  public password: string;

  //public EmailValidator: string;
  private errorMessage: string;
  public login: string;

  constructor() {
    this.errorMessage = "";
  }

  //get error message
  getErrorMessage(): string {
    return this.errorMessage;
  }//end getErrorMessage


  //check for save
  canSaveEdit(): boolean {
    //login
    if (this.email === undefined || this.email === null || this.email.trim() === "") {
      this.errorMessage = "Veuillez saisir votre nom d'utilisateur"; return false;
    }
    //nom
    if (this.password === undefined || this.password === null || this.password.trim() === "") {
      this.errorMessage = "Veuillez saisir votre nom"; return false;
    }
    //prenoms



    return true;
  }//end canSaveEdit



}
