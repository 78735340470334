<div class="row">
    <div class="col-sm-12">
        <!-- <app-card cardTitle="Liste des abonnés" cardClass="card-datatable" [options]="false"> -->
            <div class="row align-items-center m-l-0">
                <div class="col-sm-6"></div>
                <div class="col-sm-6 text-right mb-2">
                   
                </div>
            </div>
            <div class="table-responsive">
                <table datatable class="table table-striped table-bordered nowrap table-hover">
                    <thead>
                        
                        <tr class="">
                            <th class="text-center">#</th>
                            <th class="">Nom</th>
                            <th class="">Prénom</th>
                            <th class="">Sexe</th>
                            <th class="">Téléphone</th>
                            <th class="">Email</th>                            
                            <th class="text-center">Action</th>


                        </tr>
                    </thead>
                    <tbody>

                        <tr *ngIf="isLoading">
                            <td class="text-center" colspan="8">
                                Veuillez patienter pendant le chargement des données...
                            </td>
                        </tr>

                        <tr *ngFor="let item of items ">
                            <!-- <tr *ngFor="let item of items | itPaginator: (page-1)*pageSize: page*pageSize; let i = index"> -->
                            <td class="text-center">{{item.id}}</td>
                            <td class="">{{item.nom}}</td>
                            <td class="">{{item.prenom}}  </td>
                            <td class="">{{item.sexe}}</td>
                            <td class="">{{item.telephone}}  </td>
                            <td class="">{{item.email}}</td>
                            <td class="text-center">

                                <div class="btn-group mx-2" (click)="editAbonne(item)">
                                    <a class="btn btn-icon btn-outline-success" title="Editer"><i class="feather icon-edit"></i></a>
                                </div>
                                <div class="btn-group mx-2" (click)="goToVisitesAbonne(item)">
                                    <a class="btn btn-icon btn-outline-success" title="Visites"><i class="feather icon-globe"></i></a>
                                </div>
                                <div class="btn-group mx-2" (click)="goToDonsAbonne(item)">
                                    <a class="btn btn-icon btn-outline-success" title="Dons"><i class="feather icon-briefcase"></i></a>
                                </div>

                                </td>
                        </tr>

                    </tbody>

                </table>

                <!-- <ngb-pagination class='float-right mb-2' [(page)]="page" [pageSize]="pageSize"
                [collectionSize]="items.length"></ngb-pagination> -->

            </div>
        <!-- </app-card> -->
    </div>
</div>
