import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-articles-redacteurs',
  templateUrl: './edit-articles-redacteurs.component.html',
  styleUrls: ['./edit-articles-redacteurs.component.scss']
})
export class EditArticlesRedacteursComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
