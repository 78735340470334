import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-abonne-visites',
  templateUrl: './abonne-visites.component.html',
  styleUrls: ['./abonne-visites.component.scss']
})
export class AbonneVisitesComponent implements OnInit {

  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  isLoading: boolean;
  selectedAbonne: any= {};

  constructor(private router: Router, private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService, private modalService: NgbModal,
    private utils: UtilsService,) { }

  ngOnInit(): void {
    this.selectedAbonne =  this.localStorageService.getAbonne();
    //console.log(this.abonneVisites);
    
    this.listVisitesAbonne();
  }

   //  liste des visites par abonne
   listVisitesAbonne(): void {

    this.items = [];
    this.isLoading = true;

    this.paramService.getVisiteAbonneList(this.selectedAbonne).subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;

       // console.log(result);
        if (resp === null || resp === undefined) {
          this.utils.notifyErrorMessage ("Une erreur est survenue lors du chargement des visites", "Visites");
          this.items = this.items;
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage (result.message, "Visites");
          this.items = this.items;
          return;
        }
        this.items = resp.data;
        //console.log(this.items);   
      
      },
      err => {
        this.isLoading = false;
        this.utils.notifyErrorMessage ("Une erreur est survenue lors du chargement des visites", "Visites");
        return;
      }
    );
  }
//  end liste des visite par abonne



}
