<!-- Section: Design Block -->
<section class="d-flex justify-content-center align-items-center vh-100">
    <style>
      .cascading-right {
        margin-right: -50px;
      }
  
      @media (max-width: 991.98px) {
        .cascading-right {
          margin-right: 0;
        }
      }
    </style>
  
    <!-- Jumbotron -->
    <div class="container py-4">
      <div class="row g-0 align-items-center justify-content-center">
        <div class="col-lg-6 mb-5 mb-lg-0">
          <img src="../../../assets/register.png" class="w-100 rounded-4 shadow-4" alt="" />
        </div>
        <div class="col-lg-6 mb-5 mb-lg-0">
          <div class="card cascading-right bg-body-tertiary" style="backdrop-filter: blur(30px);">
            <div class="card-body p-5 shadow-5 text-center">
              <h2 class="fw-bold mb-5">Inscrivez-vous maintenant</h2>
              <form>
                <!-- 2 column grid layout with text inputs for the first and last names -->
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label" for="form3Example1">Nom</label>
                      <input type="text" id="form3Example1" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label" for="form3Example2">Prénom</label>
                      <input type="text" id="form3Example2" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label" for="form3Example1">Email</label>
                      <input type="text" id="form3Example1" typê="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label" for="form3Example2">Télephone</label>
                      <input type="text" id="form3Example2" type="number" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div data-mdb-input-init class="form-outline">
                      <label class="form-label" for="form3Example2">Mot de passe</label>
                      <input type="password" id="form3Example2" class="form-control" />
                    </div>
                  </div>
                </div>
  
                <button type="submit" data-mdb-button-init data-mdb-ripple-init class="btn btn btn-block mb-4" style="background-color: #009fe3; color: white;">
                  S'inscrire
                </button>
  
                <div class="form-check d-flex justify-content-center mb-4">
                  <label style="cursor: pointer;" (click)="login()" class="form-check-label" for="form2Example33">
                    Vous avez un compte?
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  