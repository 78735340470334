import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthentificationService } from '../../shared/services/http/authentication/authentifications.service';
import { UtilsService } from '../../shared/services/utils.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { AuthService } from 'src/app/shared/services/http/authentication/auth.service';
import { user } from './../../shared/model/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';




type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public formErrors: FormErrors = {
    'email': '',
    'password': '',
  };
  title = "Connexion"
  public errorMessage: any;
  isLoading: boolean = false;
  isPasswordShown: boolean = false;
  isPasswordVisible: boolean = false;

  item: user = new user()
  constructor(private fb: FormBuilder, private authService: AuthService,
    private router: Router, private dataservice: AuthentificationService,
    private utils: UtilsService,
    private ngxloader: NgxUiLoaderService,
    private localStorage: LocalStorageService) {


  }

  ngOnInit() {
  }

  //  }
  login() {

    if (!this.item.canSaveEdit()) {

      this.utils.notifyErrorMessage("wcvodoo", this.item.getErrorMessage())
      return;
    }
    this.isLoading = true;
    this.ngxloader.start();
    this.dataservice.signIn(this.item)
      .subscribe((result) => {
        //this.router.navigate(["/admin/accueil"]);

        this.isLoading = false;
        if (result === undefined) {
          this.isLoading = false;
          this.utils.notifyErrorMessage("Une erreur est survenue.veuillez patienter puis réessayer dans quelques minutes.", this.title);
          this.ngxloader.stop();

        }
        if (result.status === "error") {
          this.isLoading = false;
          let error: string = result.message;
          this.utils.notifyErrorMessage(error, this.title);
          this.ngxloader.stop();

          return;
        }
        else {
          //console.log(result);
          this.router.navigate(['/dashboard/default']);
          this.localStorage.saveUserConnected(result)
          this.ngxloader.stop();

        }
      }, (error: any) => {
        this.isLoading = false;
        //let errorMsg: string = "Vos paramètres de connexion sont incorrects.";
        let errorMsg: string = error.error.message
        this.utils.notifyErrorMessage(errorMsg, this.title);
        this.ngxloader.stop();

        return;
      });
  }


  // this.authService.login(this.collaborateur).subscribe((result) => {
  //   this.loaderService.stop();
  //   if (result === undefined) {
  //    let error: string = "Une erreur est survenue. Si ce message persiste, veuillez patienter puis réessayer dans quelques minutes.";
  //     this.utils.notifyErrorMessage(error, "Connexion"); return;
  //   }
  //   if (result.status === "error") {
  //     let error: string = result.message;
  //     this.utils.notifyErrorMessage(error, "Connexion");
  //     return;
  //   }
  //   else
  //   {
  //    let userLogin : any = result;
  //     //console.log(result)
  //     //save user
  //     //let user : any = { login : userLogin.email, motDePasse : userLogin.password, role : userLogin.role.code, nom : userLogin.nom, prenom : userLogin.prenom, tel : userLogin.tel, sexe : userLogin.sexe };
  //     ////console.log(user);
  //     let test = this.localStorage.saveUserConnected(result);
  //     //console.log(test)
  //     setTimeout(() => { this.loaderService.stop(); }, 5000);
  //     this.router.navigate(["/admin/accueil"]);
  //   }
  // }, (error: any) => {
  //   let errorMsg: string = error.message;
  //   this.utils.notifyErrorMessage(errorMsg, "Connexion");
  //   return;
  // })
  //}
  //get login
  getLogin() {
    this.isLoading = true
    this.dataservice.getAuth().subscribe((data) => {
      //console.log(data);
      this.isLoading = false
      // this.listeItems = data;/
    });
  }

  //toogle button
  toogleState() {
    this.isPasswordShown = !this.isPasswordShown;
  }//end toogleState

  //set classes
  getType() {
    if (this.isPasswordShown) {
      return "text";
    } else {
      return "password";
    }
  }//end setMyClasses

  //toogle password View
  tooglePasswordView(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }//fin tooglePasswordView

  //get get input type
  getInputType(): string {
    if (this.isPasswordVisible) { return "text"; } else { return "password"; }
  }//fin getInputType

  //get icon visible or not
  getIconValue(): string {
    if (this.isPasswordVisible) { return "fa fa-eye"; } else { return "fa fa-eye-slash"; }
  }//fin getIconValue

  register(){

    this.router.navigate(['/auth/register'])
  }
}
