import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-abonne-list',
  templateUrl: './abonne-list.component.html',
  styleUrls: ['./abonne-list.component.scss']
})
export class AbonneListComponent implements OnInit {

  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;

  isLoading: boolean;

  constructor(private router: Router, private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService, private modalService: NgbModal,
    private utils: UtilsService,) { }

  ngOnInit(): void {
    this.getAbonneList();

  }

  //récupérer la liste des abonnes
  getAbonneList(): void {
    this.items = [];
    this.isLoading = true;
    this.paramService.getAbonneList().subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;

        // console.log(result);
        if (resp === null || resp === undefined) {
          this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des abonnés", "Profils");
          this.items = this.items;
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage(result.message, "Profils");
          this.items = this.items;
          return;
        }
        this.items = resp.data;
        //console.log(this.items);   

      },
      err => {
        this.isLoading = false;
        this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des abonnés", "Profils");
        return;
      }
    );
  }
  //end getAbonneList

  editAbonne() {


  }


  goToVisitesAbonne(item: any) {
    this.localStorageService.saveAbonne(item);
    //console.log(item);

    this.router.navigate(["parametres/avis-abonne"]);
  }


  goToDonsAbonne(item: any) {
    this.localStorageService.saveAbonne(item);
    //console.log(item);

    this.router.navigate(["parametres/abonne-don"]);

  }




}
