import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment'
import { Item } from 'angular2-multiselect-dropdown';
var today = new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

export interface Modele{
  id: number;
  libelle: string;
  code: string;
  created_at: string | null;
  updated_at: string | null;
  marque_id: number;
}


@Injectable({
  providedIn: 'root'
})

export class OperationService {

  public observer: Subscriber<{}>;
 

  constructor(private http: HttpClient) { }

  //victime
    // get liste des dmandes de modification
    getListDemandeModification(): Observable<any>{
      return this.http.get( environment.apiUrl + 'admin/offres-a-modifier');
    }

    // get liste des annonceurs
    getListAnnonceurs(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/annonceurs' , item);
    }
   
    //delete intervention
    deleteAnnonceur(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/remove-annonceur' , item);
    }

    deleteDemande(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/remove-demande' , item);
    }

    
    
  //end victime

  //intervention
    // get all offres
    getListingoffre(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/vehicules' , item);
    }

    getAnnonceparPeriode(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/statistique-periode' , item);
    }


    //get intervention en attente
    getListingOffreEnAttennte(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/vehicule-en-attente-de-validation' , item);
    }
    // create intervention
    addIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'interventions/save-intervention' , item);
    }
    //delete offre
    deleteOffre(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'offer/remove-vehicule' , item);
    }
    //edit intervention
    editIntervention(item): Observable<any>{
      return this.http.post( environment.apiUrl + '' , item);
    }
    //validate intervention
    validateOffre(item): Observable<any>{
      return this.http.post( environment.apiUrl + 'admin/validated-vehicule' , item);
    }


    

  //get data dashboard
  getDashboardData(): Observable<any>{
    return this.http.get( environment.apiUrl + 'statistiques' );
  }

  getInterventionByDate(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'interventions/get-intervention-by-date' , item);
  }

  getInterventionValidateByDate(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'interventions/admin/dashboard/interventions/nonvalidé' , item);
  }

  // Interventions obligatoires//

 
 
  

  
  getNewVoiture(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'admin/save-vip-vehicule' , item);
  }

  // searche criteria

  searchVehiculeWithCriteria(item): Observable<any>{
    return this.http.post( environment.apiUrl + 'offer/search-vehicule-with-criteriaweb ' , item);
  }

  //
 
  // fin caracteristiques
}
