<div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel1">Modifier un contrat</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
      ></button>
    </div>
    <div class="modal-body">
        <form role="form">
            <div class="card-body">
                <div class="row">
                    

                    <div class="col-sm-1">

                    </div>
                   <!-- left column -->
                   <div class="col-sm-5">
                    <div class="form-group">
                        <label for="nomprenomClient">Client</label>
                        <select class="custom-select" name="nomprenomClient" >
                            <option ></option>
                        </select>
                  
                    </div>
                    <div class="form-group">
                        <label for="DateDebut">Date de debut</label>&nbsp;
                        <input type="date"  class="form-control" id="DateDebut" name="DateDebut" [(ngModel)]="contrat.date_debut" >
                    </div>
                    <div class="form-group">
                        <label for="lien">Montant</label>
                        <input type="number" class="form-control" id="montant" [(ngModel)]="contrat.montant" placeholder="000 000"  name="montant">
                    </div>
                    
                </div>
                <!-- end left column -->

                <!-- right column -->
                <div class="col-sm-5">
                    <div class="form-group">
                        <label for="titreVideo">Video</label>
                        <select class="custom-select" name="titreVideo">
                            <option  ></option>
                        </select>
                  
                    </div>
                    <div class="form-group">
                        <label for="DateFin">Date de fin</label>&nbsp;
                        <input type="date"  class="form-control" id="DateFin" [(ngModel)]="contrat.date_fin" name="DateFin">
                        
                    </div>
                   
                </div>
                <!-- end right column -->
                    
                    <div class="col-sm-1">

                    </div>
                </div>
            </div>
            <!-- /.card-body -->

           
        </form>
    </div>
    <div class="modal-footer">

        <button type="button" class="btn btn-warning" (click)="abort()">
            Desactiver
          </button>
      <button type="button" class="btn btn-danger" (click)="abort()">
        Annuler
      </button>
      <button type="button" class="btn btn-success" (click)="saveItem()" >Valider</button>
    </div>
  </div>

