<div class="row">
    
    <div class="card col-sm " style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/actualit__ss.jpg" alt="Card image cap"> &nbsp;
        <h5 class="card-title text-center mt-3"><strong>Dons</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div>&nbsp; &nbsp;
    <div class="card col-sm" style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/cour.jpg" alt="Card image cap">&nbsp;
        <h5 class="card-title text-center mt-2"><strong>Visites</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div> &nbsp; &nbsp;
    <div class="card col-sm" style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/deno.jpg" alt="Card image cap">&nbsp;
        <h5 class="card-title text-center mt-3"><strong>Contrats</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div>&nbsp; &nbsp;
    <div class="card col-sm " style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/actualit__ss.jpg" alt="Card image cap"> &nbsp;
        <h5 class="card-title text-center mt-3"><strong>Videos</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div>&nbsp; &nbsp;
    <div class="card col-sm" style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/cour.jpg" alt="Card image cap">&nbsp;
        <h5 class="card-title text-center mt-2"><strong>Vues</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div> &nbsp; &nbsp;
    <div class="card col-sm" style="width: 18rem;">
        <h1></h1>
        <img class="card-img-top" src="../../../assets/img/deno.jpg" alt="Card image cap">&nbsp;
        <h5 class="card-title text-center mt-3"><strong>Abonnés</strong></h5>
        <div class="card-body">
          <p class="card-text text-center border bg-primary" style="font-size: 20px;"><strong>0</strong></p>
        </div>
    </div>


    
  </div>