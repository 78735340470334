import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthentificationService } from '../../shared/services/http/authentication/authentifications.service';
import { UtilsService} from '../../shared/services/utils.service';
import { LocalStorageService } from '../../shared/services/storage/localstorage.service';
import {  PasswordReset} from "../../shared/model/password_reset.model";




@Component({
  selector: 'app-login',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  title = "Connexion"
  public errorMessage: any;
  isLoading: boolean = false;
  item: PasswordReset = new PasswordReset()
  userData:any;
  isPasswordShown: boolean = false;
  isPasswordVisible: boolean = false;
  constructor(
    private router: Router, private dataservice: AuthentificationService,
    private utils:UtilsService,
    private localStorage:LocalStorageService) {
    
  }

  ngOnInit() {
  }

  resetPassword(){
    this.isLoading = true;
    this.userData = this.localStorage.getUserConnected(); 
    this.item.reference = this.userData.reference;

    //console.log(this.item);
    if(this.item.canSave() === false){
      this.utils.notifyErrorMessage(this.item.getErrorMessage(),"Modification de mot de passe");
      this.isLoading = false;
      return;
    }else{
      this.dataservice.resetPasssword(this.item).subscribe(
        response => {
          let resp= response;
          //console.log(resp)
          this.isLoading = false;
          if (resp.status == "success") {
            this.utils.notifySuccessMessage("Le mot de passe a été modifié avec succès", "Modification de mot de passe"); 
            this.router.navigate(["auth/login"]);
          }else if (resp.status == "error"){
            // let message = "Une erreur est survenue";
            this.utils.notifyErrorMessage(resp.message , "Modification de mot de passe")
          }
          
         }
        , err => {
              this.utils.notifyErrorMessage("Une erreur est survenue", "Modification de mot de passe");
              return;
            }
      );
    
    }
    
    
  }
   //toogle button
   toogleState() {
    this.isPasswordShown = !this.isPasswordShown;
  }//end toogleState

  //set classes
  getType() {
    if (this.isPasswordShown) {
      return "text";
    } else {
      return "password";
    }
  }//end setMyClasses

  //toogle password View
  tooglePasswordView(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }//fin tooglePasswordView

  //get get input type
  getInputType(): string {
    if (this.isPasswordVisible) { return "text"; } else { return "password"; }
  }//fin getInputType

  //get icon visible or not
  getIconValue(): string {
    if (this.isPasswordVisible) { return "fa fa-eye"; } else { return "fa fa-eye-slash"; }
  }//fin getIconValue

    

}
