import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { LocalStorageService } from 'src/app/shared/services/app/localstorage.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-abonne-dons',
  templateUrl: './abonne-dons.component.html',
  styleUrls: ['./abonne-dons.component.scss']
})
export class AbonneDonsComponent implements OnInit {


  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;
  isLoading: boolean;
  selectedAbonnee: any = {};
  constructor(private router: Router, private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService, private modalService: NgbModal,
    private utils: UtilsService) { }

  ngOnInit(): void {
    this.selectedAbonnee =  this.localStorageService.getAbonne();
    //console.log(this.selectedAbonnee.id);

    this.listDonsAbonne();
   
  }


  listDonsAbonne(): void {

    this.items = [];
    this.isLoading = true;

    let itemDon: any = { id: this.selectedAbonnee.id } ;
    //console.log(itemDon);

    this.paramService.getDonAbonneList(itemDon).subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;

       
        if (resp === null || resp === undefined) {
          this.utils.notifyErrorMessage ("Une erreur est survenue lors du chargement des dons", "Dons");
          this.items = this.items;
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage (result.message, "Dons");
          this.items = this.items;
          return;
        }
        this.items = resp.data;
        //console.log(this.items);   
      
      },
      err => {
        this.isLoading = false;
        this.utils.notifyErrorMessage ("Une erreur est survenue lors du chargement des dons", "Dons");
        return;
      }
    );
  }
//  end liste des dons par abonne

}
