import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    // data: {
    //   breadcrumb: "Dashboard"
    // }
  },
 
  {
    path: 'parametres',
    loadChildren: () => import('../../components/parametres/parametres.module').then(m => m.ParametresModule),
    data: {
      //breadcrumb: "Paramètres"
    }
  },

];