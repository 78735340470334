import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ContratCreateComponent } from '../contrat-create/contrat-create.component';
import { ContratEditComponent } from '../contrat-edit/contrat-edit.component';

@Component({
  selector: 'app-contrat-list',
  templateUrl: './contrat-list.component.html',
  styleUrls: ['./contrat-list.component.scss']
})
export class ContratListComponent implements OnInit {

  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;

  isLoading: boolean;

  constructor(    private router: Router, private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService, private modalService: NgbModal,
    private utils: UtilsService,) { }

  ngOnInit(): void {
    this.getContratList();
  }


    //récupérer la liste des contrats
    getContratList(): void {
      this.items = [];
      this.isLoading = true;
      //this.loaderService.start();
        
  
      this.paramService.getContratList().subscribe(
        (result: any) => {
          this.isLoading = false;
          let resp = result;
          
          //this.loaderService.stop();
  
          if (resp === null || resp === undefined) {
            this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des contrats", "Contrat");
            this.items = this.items;
            return;
          }
          if (resp.status === "error") {
            this.utils.notifyErrorMessage(result.message, "Contrat");
            this.items = this.items;
            return;
          }
          this.items = resp.data;
          console.log(this.items);
        },
        err => {
          this.loaderService.stop();
          this.isLoading = false;
          this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des contrats", "Contrat");
          return;
        }
      );
    } //end getContratList

    goToCreateContrat(){
      
      const ModalRef = this.modalService.open(ContratCreateComponent, { size : "lg", backdrop : "static" }).result.then((result) =>{
        
      }, (reason) => {
        if(reason){
          this.getContratList();
        }
      });
  

    }

    editContrat(data){
      this.localStorageService.saveClient(data);
      const ModalRef = this.modalService.open(ContratEditComponent, { size : "lg", backdrop : "static" }).result.then((result) =>{
        
      }, (reason) => {  
         if(reason){
        this.getContratList();
      }
    });

    }

    // removeVideo(data){
    //   this.localStorageService.savedatadelete(data);
    //   const ModalRef = this.modalService.open(VideoDeleteComponent, { size : "lg", backdrop : "static" });
    //   this.getVideoList();

    //  }
  
}
