import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presentation-site-create',
  templateUrl: './presentation-site-create.component.html',
  styleUrls: ['./presentation-site-create.component.scss']
})
export class PresentationSiteCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
