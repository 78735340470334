import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable()
export class UtilsService {

  private monthList: string[] = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    constructor(private toastr: ToastrService,) { }


     // notifie une erreur
    notifyErrorMessage(message : string, title: string,) {
        this.toastr.error(message, title);
    }//end notifyErrorMessage

    // notifie une information
    notifyInfoMessage(message : string, title: string,) {
        this.toastr.info(message, title);
    }//end notifyInfoMessage

    // notifie un succes
    notifySuccessMessage(message : string, title: string,) {
        this.toastr.success(message, title);
    }//end notifySuccessMessage

      // demande  unen confirmation
      askConfirmation(message : string,) {
        return confirm(message);
    }//end askConfirmation


    sansAccent(text:string){
        var accent = [
            /[\300-\306]/g, /[\340-\346]/g, 
            /[\310-\313]/g, /[\350-\353]/g, 
            /[\314-\317]/g, /[\354-\357]/g, 
            /[\322-\330]/g, /[\362-\370]/g, 
            /[\331-\334]/g, /[\371-\374]/g, 
            /[\321]/g, /[\361]/g, 
            /[\307]/g, /[\347]/g, 
        ];
        var noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
         
      
        for(var i = 0; i < accent.length; i++){
            text = text.replace(accent[i], noaccent[i]);
        }
         
        return text;
    }


    // // genere une url darticle a partir du titre
    generateUrlFromTitle(title : string) {

        let getTitle: any = "";
        let url: any = "";


        //mettre le titre en minuscule
      url=title.toLowerCase();
        //remplacer chaque espace par un tiret 
        url= url.replace(" ", '-')
        //remplacer les lettres accentuees par leur equivalent non accentuees
        url=this.sansAccent(url);
    //  url=  document.write(url.iso2uni());
        //var y=document.createElement('span');
       // y.innerHTML=url;
      //  url=y.innerHTML;
        //limiter le nombre de caracteres a 50 au maximum
      url= url.substr(0,50);

       return url;
    }//end generateUrlFromTitle


 // affiche un message de succès
 displaySuccessMessage(data : string, router: Router,) {
    Swal.fire('MyWorld Web',
    data, 'success');
  }//end displaySuccessMessage

  displayErrorMessage(message : string, router: Router,) {
    Swal.fire({
      customClass: { container: 'myCustomSwal' },
      icon: 'error',
      title: message
    });
  } //end displayErrorMessage

  //format a date
  formatDate(_date: Date, format: string): string {
    let date: Date = new Date(_date);
    if (!format)
        format = "MM/dd/yyyy";

    let month: number = date.getMonth() + 1;
    let year: number = date.getFullYear();

    format = format.replace("MM", this.padL(month.toString(), 2, "0"));

    if (format.indexOf("yyyy") > -1)
        format = format.replace("yyyy", year.toString());
    else if (format.indexOf("yy") > -1)
        format = format.replace("yy", year.toString().substr(2, 2));

    format = format.replace("dd", this.padL(date.getDate().toString(), 2, "0"));

    var hours = date.getHours();
    if (format.indexOf("t") > -1) {
        if (hours > 11)
            format = format.replace("t", "pm")
        else
            format = format.replace("t", "am")
    }
    if (format.indexOf("HH") > -1)
        format = format.replace("HH", this.padL(hours.toString(), 2, "0"));
    if (format.indexOf("hh") > -1) {
        if (hours > 12) hours - 12;
        if (hours == 0) hours = 12;
        format = format.replace("hh", this.padL(hours.toString(), 2, "0"));
    }
    if (format.indexOf("mm") > -1)
        format = format.replace("mm", this.padL(date.getMinutes().toString(), 2, "0"));
    if (format.indexOf("ss") > -1)
        format = format.replace("ss", this.padL(date.getSeconds().toString(), 2, "0"));
    return format;
}//end formatDate

//padR
padR(str: string, width: number, pad: string): string {
    if (!width || width < 1)
        return str;

    if (!pad) pad = " ";
    var length = width - str.length
    if (length < 1) str.substr(0, width);

    return (str + this.repeat(pad, length)).substr(0, width);
}//end padR

//padL
padL(str: string, width: number, pad: string) {
    if (!width || width < 1)
        return str;

    if (!pad) pad = " ";
    var length = width - str.length
    if (length < 1) return str.substr(0, width);

    return (this.repeat(pad, length) + str).substr(0, width);
}//end padL

//repeat
repeat(chr: string, count: number): string {
    var str = "";
    for (var x = 0; x < count; x++) { str += chr };
    return str;
}//end repeat

//get current month
getCurrentMonth(): string {
    let monthName = this.monthList[new Date().getMonth()];
    return monthName;
}//end getCurrentMonth



  
}