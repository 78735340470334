/**
 * Created by utilisateur on 31/03/2017.
 */

/**
 * Created by utilisateur on 29/03/2017.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {



  constructor(private http: HttpClient) { }

  //enregistre un utilisateur connecte
  public saveUser(obj: any): void {
    this.saveToSession("M_MY_WORLD_USER", obj);
  };
  //retourne un user
  public getUser(): any {
    return this.readFromSession("M_MY_WORLD_USER");
  };
  

  //enregistre un groupe
  public saveGroupe(obj: any): void {
    this.saveToSession("M_MY_WORLD_GROUPE", obj);
  };

  //retourne un groupe
  public getGroupe(): any {
    return this.readFromSession("M_MY_WORLD_GROUPE");
  };
  //enregistre un profil
  public saveProfil(obj: any): void {
    this.saveToSession("M_MY_WORLD_PROFIL", obj);
  };
  //retourne un profil
  public getProfil(): any {
    return this.readFromSession("M_MY_WORLD_PROFIL");
  };

  //enregistre un client
  public saveClient(obj: any): void {
    this.saveToSession("M_MY_WORLD_CLIENT", obj);
  };
  //retourne un client
  public getClient(): any {
    return this.readFromSession("M_MY_WORLD_CLIENT");
  };

  //enregistre un client
  public saveArticle(obj: any): void {
    this.saveToSession("M_MY_WORLD_ARTICLE", obj);
  };
  //retourne un client
  public getArticle(): any {
    return this.readFromSession("M_MY_WORLD_ARTICLE");
  };




  //enregistre un profil
  public saveAdmin(obj: any): void {
    this.saveToSession("M_MY_WORLD_ADMIN", obj);
  };
  //retourne un profil
  public getAdmin(): any {
    return this.readFromSession("M_MY_WORLD_ADMIN");
  };

  
    //enregistre un profil à supprimer
    public saveDataDeleteProfil(obj: any): void {
      this.saveToSession("M_MY_WORLD_ADMIN", obj);
    };
  
    //retourne un profil à supprimer
    public getDataDeleteProfil(): any {
      return this.readFromSession("M_MY_WORLD_ADMIN");
    };
  

   //enregistre un profil
   public saveUserConnected(obj: any): void {
    this.saveToSession("M_MY_WORLD_USER_CONNECTED", obj);
  };
  //retourne un profil
  public getUserConnected(): any {
    return this.readFromSession("M_MY_WORLD_USER_CONNECTED");
  };

  //enregistre un pays
  public savePays(obj: any): void {
    this.saveToSession("M_MY_WORLD_PAYS", obj);
  };

  //retourne un pays
  public getPays(): any {
    return this.readFromSession("M_MY_WORLD_PAYS");
  };

  //enregistre un pays à supprimer
  public savedatadeletePays(obj: any): void {
    this.saveToSession("M_MY_WORLD_PAYS", obj);
  };

  //retourne un pays supprimé
  public getdatadeletePays(): any {
    return this.readFromSession("M_MY_WORLD_PAYS");
  };

   //enregistre une destination
   public saveDestination(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un vaudoun
  public getDestination(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };
   //enregistre un media
   public saveMedia(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un media
  public getMedia(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };

  //enregistre un vaudoun à supprimer
  public savedatadeleteDestination(obj: any): void {
    this.saveToSession("M_MY_WORLD_DESTINATION", obj);
  };

  //retourne un vaudoun supprimé
  public getdatadeleteDestination(): any {
    return this.readFromSession("M_MY_WORLD_DESTINATION");
  };


  //enregistre un vaudoun
  public saveVaudoun(obj: any): void {
    this.saveToSession("M_MY_WORLD_VAUDOUN", obj);
  };

  //retourne un vaudoun
  public getVaudoun(): any {
    return this.readFromSession("M_MY_WORLD_VAUDOUN");
  };

  //enregistre un vaudoun à supprimer
  public savedatadeleteVaudoun(obj: any): void {
    this.saveToSession("M_MY_WORLD_VAUDOUN", obj);
  };

  //retourne un vaudoun supprimé
  public getdatadeleteVaudoun(): any {
    return this.readFromSession("M_MY_WORLD_VAUDOUN");
  };

  //enregistre une video
  public saveVideo(obj: any): void {
    this.saveToSession("M_MY_WORLD_VIDEO", obj);
  };

  //retourne une vidéo
  public getVideo(): any {
    return this.readFromSession("M_MY_WORLD_VIDEO");
  };

   //enregistre une video à supprimer
   public savedatadeleteVideo(obj: any): void {
    this.saveToSession("M_MY_WORLD_VIDEO", obj);
  };

  //retourne une vdeo supprimée
  public getdatadeleteVideo(): any {
    return this.readFromSession("M_MY_WORLD_VIDEO");
  };

   //retourne un contrat
   public getContrat(): any {
    return this.readFromSession("M_MY_WORLD_CONTRAT");
  };

    //enregistre un abonne
    public saveAbonne(obj: any): void {
      this.saveToSession("M_MY_WORLD_ABONNE", obj);
    };
    //retourne un abonne
    public getAbonne(): any {
      return this.readFromSession("M_MY_WORLD_ABONNE");
    };




  private saveToSession(key: string, value: any): void {
    var stringified = JSON.stringify(value);
    var jsonValue = btoa(stringified);
    localStorage.setItem(key, jsonValue);
  };

  private readFromSession(key: any): any {
    var result = null;
    try {
      var json = localStorage.getItem(key);
      var result = JSON.parse(atob(json));
    } catch (e) {
      result = null;
    }
    return result;
  };

}

