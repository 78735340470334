import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pays-delete',
  templateUrl: './pays-delete.component.html',
  styleUrls: ['./pays-delete.component.scss']
})
export class PaysDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
