/**
 * Created by utilisateur on 26/03/2017.
 */


import { Injectable } from '@angular/core';
// import { Headers, Http } from '@angular/http';
// import { Observable } from 'rxjs/Observable';
import { AppService } from '../../services/app/app.service';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class UserService {

  private apiUrl : string;
  private headers : Headers;

  constructor(private http: HttpClient, private appService: AppService) {
  }

  
 //get profil list
 getProfilList(item: any) {
  return this.http.get(`${this.appService.getBaseUrl()}users`)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin getProfilList

//create profil
createProfil(item: any)  {
  return this.http.post<any[]>(`${this.appService.getBaseUrl()}utilisateur`, item, this.appService.httpOptions)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin createProfil

 //get Role list
 getRoleList(item: any) {
  return this.http.get<any[]>(`${this.appService.getBaseUrl()}role/{id}`)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin get Role list


//update profil
updateProfil(item: any) {
  return this.http.put<any[]>(`${this.appService.getBaseUrl()}user`, item, this.appService.httpOptions)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin updateProfil


//   ///////////////////////////////////////start client /////////////////////////////////


//create client
createClient(item: any) {
  return this.http.post<any[]>(`${this.appService.getBaseUrl()}client`, item, this.appService.httpOptions)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin createClient

//update client
updateClient(item: any) {
  return this.http.put<any[]>(`${this.appService.getBaseUrl()}client`, item, this.appService.httpOptions)
    .pipe(
      catchError(this.appService.handleError<any[]>(``))
    )
}//fin updateClient

  // delete profil
deleteProfil(item: any) {
  return this.http.delete(`${this.appService.getBaseUrl()}user/${item.id}`, this.appService.httpOptions
  )
    .pipe(
      catchError(this.appService.handleError<any>(``),
      )
    )
}//fin deleteProfil

//   ///////////////////////////////////////end Dossier /////////////////////////////////


   //get clients list
   getClientsList(item: any) {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}clients`)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getClientList

  //get collaborateurs list
  getCollaborateursList(item: any) {
    return this.http.get<any[]>(`${this.appService.getBaseUrl()}collaborateurs`)
      .pipe(
        catchError(this.appService.handleError<any[]>(``))
      )
  }//fin getClientList

  


//   //   ///////////////////////////////////////start profile /////////////////////////////////

//    //get profil list
//    getProfilList(item: any) : Observable<any> {
//     return this.http.get<any[]>(`${this.appService.getBaseUrl()}users`)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin getProfilList

//   //create profil
//   createProfil(item: any) : Observable<any> {
//     return this.http.post<any[]>(`${this.appService.getBaseUrl()}utilisateur`, item, this.appService.httpOptions)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin createProfil

//    //get Role list
//    getRoleList(item: any) : Observable<any> {
//     return this.http.get<any[]>(`${this.appService.getBaseUrl()}role/{id}`)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin get Role list


//   //update profil
//   updateProfil(item: any) : Observable<any> {
//     return this.http.put<any[]>(`${this.appService.getBaseUrl()}user`, item, this.appService.httpOptions)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin updateProfil


//   //   ///////////////////////////////////////start client /////////////////////////////////


//   //create client
//   createClient(item: any) : Observable<any> {
//     return this.http.post<any[]>(`${this.appService.getBaseUrl()}client`, item, this.appService.httpOptions)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin createClient

//   //update client
//   updateClient(item: any) : Observable<any> {
//     return this.http.put<any[]>(`${this.appService.getBaseUrl()}client`, item, this.appService.httpOptions)
//       .pipe(
//         catchError(this.appService.handleError<any[]>(``))
//       )
//   }//fin updateClient

//     // delete profil
//   deleteProfil(item: any) : Observable<any> {
//     return this.http.delete(`${this.appService.getBaseUrl()}user/${item.id}`, this.appService.httpOptions
//     )
//       .pipe(
//         catchError(this.appService.handleError<any>(``),
//         )
//       )
//   }//fin deleteProfil

// //   ///////////////////////////////////////end Dossier /////////////////////////////////


//      //get clients list
//      getClientsList(item: any) : Observable<any> {
//       return this.http.get<any[]>(`${this.appService.getBaseUrl()}clients`)
//         .pipe(
//           catchError(this.appService.handleError<any[]>(``))
//         )
//     }//fin getClientList

//     //get collaborateurs list
//     getCollaborateursList(item: any) : Observable<any> {
//       return this.http.get<any[]>(`${this.appService.getBaseUrl()}collaborateurs`)
//         .pipe(
//           catchError(this.appService.handleError<any[]>(``))
//         )
//     }//fin getClientList


}

