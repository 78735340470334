import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expressions-create',
  templateUrl: './expressions-create.component.html',
  styleUrls: ['./expressions-create.component.scss']
})
export class ExpressionsCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
