import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-avis-list',
  templateUrl: './avis-list.component.html',
  styleUrls: ['./avis-list.component.scss']
})
export class AvisListComponent implements OnInit {

  items: any[] = [];
  pageStartIndex: number = 0;
  limitTable: number[] = [];
  page: number = 1;
  pageSize: number = 10;

  isLoading: boolean;

  constructor(    private router: Router,
     private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService,
    private localStorageService: LocalStorageService,
    private paramService: ParameterService,
     private modalService: NgbModal,
    private utils: UtilsService,) { }

  ngOnInit(): void {
    this.getAvisList();
  }

  //récupérer la liste des avis
  getAvisList(): void {
    this.isLoading = true;
    //this.loaderService.start();
      this.items = [];

    this.paramService.getAvisList().subscribe(
      (result: any) => {
        this.isLoading = false;
        let resp = result;
        //this.loaderService.stop();

        if (resp === null || resp === undefined) {
          this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des avis", "Avis");
          this.items = this.items;
          return;
        }
        if (resp.status === "error") {
          this.utils.notifyErrorMessage(result.message, "Avis");
          this.items = this.items;
          return;
        }
        this.items = resp.data;
        console.log(this.items);
      },
      err => {
        this.loaderService.stop();
        this.isLoading = false;
        this.utils.notifyErrorMessage("Une erreur est survenue lors du chargement des avis", "Avis");
        return;
      }
    );
  } //end getAvisList


    editAbonne(){


    }


    goToVisitesAbonne(item: any){
      this.localStorageService.saveAbonne(item);
      //console.log(item);
      
      this.router.navigate(["/admin/activites/abonne-visite"]);
    }


    goToDonsAbonne(item: any){
      this.localStorageService.saveAbonne(item);
      //console.log(item);

      this.router.navigate(["/admin/activites/abonne-don"]);

    }




}
