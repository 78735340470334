import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { contrat } from 'src/app/shared/model/contrat';
import { LibraryService } from 'src/app/shared/services/app/library.service';
import { ParameterService } from 'src/app/shared/services/http/parameters/parameters.service';
import { LocalStorageService } from 'src/app/shared/services/storage/localstorage.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Component({
  selector: 'app-contrat-edit',
  templateUrl: './contrat-edit.component.html',
  styleUrls: ['./contrat-edit.component.scss']
})
export class ContratEditComponent implements OnInit {
  contrat: contrat = new contrat();
  ContratToEdit;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router, private loaderService: NgxUiLoaderService,
    private libraryService: LibraryService, private activeModal: NgbActiveModal,
    private userService: UserService,
    private utils: UtilsService, private  paramService: ParameterService,

  ) {
   
   
  }
  //ng on init
  ngOnInit() { 
    this.ContratToEdit = this.localStorageService.getContrat();
    if (this.ContratToEdit === null || this.ContratToEdit === undefined) {
      return;
    }
    this.contrat.date_realisation = this.ContratToEdit.date_realisation;
    this.contrat.montant = this.ContratToEdit.montant;
    this.contrat.date_debut = this.ContratToEdit.date_debut;
    this.contrat.date_fin = this.ContratToEdit.date_fin;


  }
  

  saveItem() {
    //controle de saisie

    // this.loaderService.start();

    // if (!this.client.canSave()) {
    //   this.loaderService.stop();
    //   this.utils.displayErrorMessage(this.client.getErrorMessage(), this.router);
    //   return;
    // }

    // //validité de l'adresse email

    // const isValidEmail = this.libraryService.isValidEmail(this.client.email);
    // if (!isValidEmail) {
    //   this.loaderService.stop();
    //   let message = "Veuillez fournir une adresse email valide pour le client";
    //   this.utils.displayErrorMessage(message, this.router);
    //   return false;
    // }

    //enregistrement
    let itemToEdit: any = this.contrat;
    itemToEdit.id = this.ContratToEdit.id;
    this.paramService.updateContrat(itemToEdit).subscribe((result: any) => {
      this.loaderService.stop();

      if (result === null || result === undefined) {
        let message = "Une erreur est survenue lors de l'édition du contrat";
        this.utils.displayErrorMessage(message, this.router);

        return;
      }

      if (result.status === "error") {
        this.utils.displayErrorMessage(result.message, this.router);

        return;
      }
      let message = "Contrat modifié avec succès";
      this.utils.displaySuccessMessage(message, this.router);

      //this.activeModal.close();
      this.activeModal.dismiss(true);

    }, err => {
      this.loaderService.stop();
      let message = "Une erreur est survenue lors de l'édition du contrat";
      this.utils.displayErrorMessage(message, this.router);
    }
    );

  } //fin saveItem

  // bouton annuler
  abort() {
    //this.activeModal.close();
    this.activeModal.dismiss(false);

  } // abort



}
