import * as EmailValidator from 'email-validator';
import { LibraryService } from '../services/app/library.service';

		export class utilisateur {
            public id: number;
            public nom: string;
            public prenom: string;
            public telephone: string;
            public agence_id: number;
            public email: string;
            public sexe: string;
            public groupe_id: number; 
            public password: string;
  

            public role_id: number;

            public nom_entreprise: string;
            public telephone_entreprise: string;
            public email_entreprise: string;
            public adressePhysiqueCLient: string;
            
            public role: any;
            //public EmailValidator: string;

            public new_password: string;
            public confirm_password: string;

            
			
			
            private errorMessage: string;
			public login: string;

    constructor(){
      this.errorMessage = "";
    }

    //get error message
    getErrorMessage(): string {
      return this.errorMessage;
    }//end getErrorMessage


      //check for save
      canSaveEdit(): boolean {
       
         //nom
         if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
          this.errorMessage = "Veuillez saisir votre nom"; return false;
        }
        //prenoms
        if(this.prenom === undefined || this.prenom === null || this.prenom.trim() === ""){
          this.errorMessage = "Veuillez saisir votre prenom"; return false;
        }
        //sexe
        if(this.sexe === undefined || this.sexe === null || this.sexe.trim() === ""){
          this.errorMessage = "Veuillez saisir votre sexe"; return false;
        }
        
        //telephone
        if(this.telephone === undefined || this.telephone === null || this.telephone.trim() === ""){
          this.errorMessage = "Veuillez saisir votre numéro de téléphone"; return false;
        }
        //prenoms
        if(this.email === undefined || this.email === null || this.email.trim() === ""){
          this.errorMessage = "Veuillez saisir votre adresse email"; return false;
        }
        //password
        if(this.password === undefined || this.password === null){
          this.errorMessage = "Veuillez saisir votre mot de passe"; return false;
        }
        
        return true;
      }
      //end canSaveEdit


      //check for save
      canSave(): boolean {
     
        //nom
        if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
          this.errorMessage = "Veuillez saisir votre nom"; return false;
        }
        //prenoms
        if(this.prenom === undefined || this.prenom === null || this.prenom.trim() === ""){
          this.errorMessage = "Veuillez saisir votre prenom"; return false;
        }
        //sexe
        if(this.sexe === undefined || this.sexe === null || this.sexe.trim() === ""){
          this.errorMessage = "Veuillez saisir votre sexe"; return false;
        }
        
        //telephone
        if(this.telephone === undefined || this.telephone === null || this.telephone.trim() === ""){
          this.errorMessage = "Veuillez saisir votre numéro de téléphone"; return false;
        }
        //prenoms
        if(this.email === undefined || this.email === null || this.email.trim() === ""){
          this.errorMessage = "Veuillez saisir votre adresse email"; return false;
        }
        //password
        if(this.password === undefined || this.password === null){
          this.errorMessage = "Veuillez saisir votre mot de passe"; return false;
        }

        return true;
      }
      //end canSave

 



    //check for login
    canLogin(): boolean {
      //nom
      if(this.email === undefined || this.email === null || this.email.trim() === ""){
          this.errorMessage = "Veuillez saisir votre adresse email"; return false;
      }

      //check email validity
      if(!EmailValidator.validate(this.email) ){
        this.errorMessage = "Veuillez saisir une adresse email valide"; return false;
    }

      //confirmation de mot de passe
      if(this.password === undefined || this.password === null || this.password.trim() === ""){
        this.errorMessage = "Veuillez indiquer votre mot de passe"; return false;
      }
      return true;
    }//end canLogin


    //check for save
    canRegister(): boolean {
        //nom entreprise
        if(this.nom_entreprise === undefined || this.nom_entreprise === null || this.nom_entreprise.trim() === ""){
          this.errorMessage = "Veuillez saisir le nom de votre entreprise"; return false;
        }

        //phone entreprise
        if(this.telephone_entreprise === undefined || this.telephone_entreprise === null || this.telephone_entreprise.trim() === ""){
          this.errorMessage = "Veuillez saisir le contact téléphonique de votre entreprise (indicatif inclus)"; return false;
        }

        // //ifu
        // if(this.ifu === undefined || this.ifu === null ){
        //   this.errorMessage = "Veuillez saisir votre numéro ifu de votre entreprise"; return false;
        // }
        // //rccm
        // if(this.rccm === undefined || this.rccm === null ){
        //   this.errorMessage = "Veuillez saisir le rccm de votre entreprise"; return false;
        // }

        // adresse email
      //   if(this.email_entreprise === undefined || this.email_entreprise === null || this.email_entreprise.trim() === ""){
      //     this.errorMessage = "Veuillez saisir l'adresse email de votre entreprise "; return false;
      // }
      //   // situation géographique
      //   if(this.situation_geographique === undefined || this.situation_geographique === null || this.situation_geographique.trim() === ""){
      //     this.errorMessage = "Veuillez saisir la situation géographique de votre entreprise "; return false;
      // }

        //login
        if(this.login === undefined || this.login === null || this.login.trim() === ""){
          this.errorMessage = "Veuillez saisir votre nom d'utilisateur"; return false;
        }

        //nom
        if(this.nom === undefined || this.nom === null || this.nom.trim() === ""){
          this.errorMessage = "Veuillez saisir votre nom"; return false;
        }
        //prenoms
        if(this.prenom === undefined || this.prenom === null || this.prenom.trim() === ""){
          this.errorMessage = "Veuillez saisir vos prénoms"; return false;
        }

        //telephone
        if(this.telephone === undefined || this.telephone === null || this.telephone.trim() === ""){
          this.errorMessage = "Veuillez saisir votre numéro de téléphone personnel (indicatif inclus)"; return false;
        }

        //mot de passe
        if(this.password === undefined || this.password === null || this.password.trim() === ""){
          this.errorMessage = "Veuillez indiquer un mot de passe à utiliser pour vous connecter"; return false;
        }
        if(this.password.trim().length <= 4){
          this.errorMessage = "Veuillez indiquer un mot de passe d'au moins 5 caractères"; return false;
        }

        //confirmation de mot de passe
        if(this.confirm_password === undefined || this.confirm_password === null || this.confirm_password.trim() === ""){
          this.errorMessage = "Veuillez confirmer votre mot de passe"; return false;
        }

        if(this.password.trim() !== this.confirm_password.trim()){
          this.errorMessage = "La confirmation du mot de passe n'est pas identique au mot de passe saisi"; return false;
        }

        // adresse email
        if(this.email === undefined || this.email === null || this.email.trim() === ""){
            this.errorMessage = "Veuillez indiquer votre adresse email personnelle "; return false;
        }       

        return true;
      }//end canRegister

        //check for update password
    canUpdatePassword(): boolean {

      //email
      if(this.password === undefined || this.password === null || this.password.trim() === ""){
          this.errorMessage = "Vous devez saisir votre ancien mot de passe."; return false;
      }

      //confirmation de mot de passe
      if(this.confirm_password === undefined || this.confirm_password === null || this.confirm_password != this.new_password || this.confirm_password.trim() === ""){
        this.errorMessage = "Veuillez indiquer votre nouveau mot de passe et le confirmer."; return false;
      }
      return true;
    }//end canUpdatePassword




    }
